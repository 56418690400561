import { useVirtualizer } from '@tanstack/react-virtual'
import React, { MutableRefObject, useCallback, useEffect, useState } from 'react'

import ContentCard, { CardType, DisplayMode } from '@/components/Content/ContentCard'
import FakeCard, { FakeCardProps } from '@/components/Content/FakeCard'
import { Content } from '@/types/content.type'

const getNumberOfColumns = (width: number): number => {
    if (width < 640) return 1 // 'sm' breakpoint
    if (width < 768) return 2 // 'md' breakpoint
    if (width < 1024) return 3 // 'lg' breakpoint
    return 4 // 'xl' and above
}

const ContentGrid = ({
    content,
    displayMode,
    parentRef,
}: {
    content: (Content | FakeCardProps)[]
    displayMode: DisplayMode

    parentRef: MutableRefObject<HTMLDivElement | null>
}) => {
    const [containerWidth, setContainerWidth] = useState(
        typeof window !== 'undefined' ? window.innerWidth : 0
    )

    const updateContainerWidth = useCallback(() => {
        setContainerWidth(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', updateContainerWidth)
        return () => {
            window.removeEventListener('resize', updateContainerWidth)
        }
    }, [updateContainerWidth])

    const getColumnWidth = () => {
        const columns = getNumberOfColumns(containerWidth)
        return containerWidth / columns
    }

    const virtualizer = useVirtualizer({
        count: Math.ceil(content.length / getNumberOfColumns(containerWidth)),
        estimateSize: getColumnWidth,
        overscan: 6,
        getScrollElement: () => parentRef.current,
    })

    return (
        <div className='container mx-auto px-4'>
            <div
                style={{
                    height: virtualizer.getTotalSize(),
                    position: 'relative',
                }}
            >
                {virtualizer.getVirtualItems().map((row) => {
                    const rowContent = content.slice(
                        row.index * getNumberOfColumns(containerWidth),
                        row.index * getNumberOfColumns(containerWidth) +
                            getNumberOfColumns(containerWidth)
                    )
                    return (
                        <div
                            key={row.key}
                            data-index={row.index}
                            ref={virtualizer.measureElement}
                            className='w-full'
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                transform: `translateY(${row.start - virtualizer.options.scrollMargin}px)`,
                            }}
                        >
                            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-3 gap-4 '>
                                {rowContent.map((item, i) => {
                                    return 'isAdCard' in item && item.isAdCard ? (
                                        <FakeCard contentData={item as FakeCardProps} />
                                    ) : (
                                        <ContentCard
                                            key={i}
                                            displayMode={displayMode}
                                            contentData={item as Content}
                                            cardType={CardType.CONTENT_GRID}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ContentGrid
